<template>
  <div class="w-full flex flex-col items-center">
    <div class="group w-full flex flex-row items-center text-xs transition ease-in-out
      focus:outline-none"
      :class="{
        'cursor-pointer':currentThreadId !== thread.id,
        'opacity-100': currentThreadId === thread.id,
        'mt-3 lg:mt-4':index===0,
       }"
    >
      <!-- User image-->
      <div class="flex flex-row items-start flex-none">
        <div class="flex-none relative">
          <Picture
            :loader-enabled="true"
            is-chat
            :src="thread.targetUser.featuredPictureUrl"
            classes="object-cover w-12 h-12 rounded-full transform duration-200 ease-out border border-black
              group-hover:border-redAccentLight"
            :class="{
            'cursor-pointer scale-100 lg:group-hover:scale-105':currentThreadId !== thread.id,
            'border-redAccentLight lg:scale-105': currentThreadId === thread.id,
            }"
          />
          <!-- New message dot -->
          <div class="absolute -right-2 -top-1">
            <template v-if="notReadThreadMessages(thread)>0 && !isBanned">
              <Badge class="mb-3 ml-1" tagName="span" variant="counterSuccess">
                {{ notReadThreadMessages(thread) > 100 ? '100+' : notReadThreadMessages(thread) }}
              </Badge>
            </template>
          </div>
          <ProfilePictureIcons
            :banned-flag = thread.targetUser.isBanned
            :hidden-flag = thread.targetUser.isHidden
          />
        </div>
      </div>
      <!--- All other things to the right -->
      <div class="w-full flex flex-row justify-between ml-3">
        <div class="w-full flex flex-col justify-between">
          <div class="flex flex-row justify-between gap-2 items-start">
            <!-- Username -->
            <div class="block whitespace-nowrap text-base leading-tight group-hover:text-redAccentLight
              flex flex-row mb-1 justify-start items-center"
              :class="{
                'cursor-pointer group-hover:opacity-70':currentThreadId !== thread.id,
                'text-redAccentLight': currentThreadId === thread.id,
              }"
              v-if="thread.targetUser"
            >
              <span
                :class="[
                  getWidth('ThreadCardName', 356),
                  {'font-semibold':notReadThreadMessages(thread)>0}
                ]"
                class="truncate"
              >
                {{ thread.targetUser.name }}
              </span>
            </div>
            <!-- Thread actions -->
            <div class="flex flex-row">
              <ThreadActions :current-thread="thread" isThread :only-delete="!!thread.isAdminMessage" />
            </div>
          </div>
          <!-- Message excerpt -->
          <div
            class="text-s leading-tight thread-span group-hover:text-redAccentLight truncate whitespace-nowrap"
            :class="[
              getWidth('ThreadCardMessage', 356),
              {
                'text-grayedDark':notReadThreadMessages(thread)===0,
                'text-black font-black':notReadThreadMessages(thread)>0,
                'cursor-pointer group-hover:opacity-70':currentThreadId !== thread.id,
                'text-redAccentLight opacity-100': currentThreadId === thread.id
              }
          ]"
          >
	          <template v-if="userData.isAdmin">
		          {{ thread.adminMessageType }}
	          </template>
	          <template v-else>
		          {{ stripTags(thread.latestMessage) }}
	          </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dot from "@/utils/dots/Dot";
import {mapGetters} from "vuex";
import ThreadActions from "@/views/content/messages/components/ThreadActions";
import {getWidth, stripTags} from "@/common/helpers/utils";
import themeConfig from "@/themeConfig";
import ProfilePictureIcons from "@/views/content/components/ProfilePictureIcons";
import Badge from "@/utils/crud/components/Badge";

export default {
  name: "ThreadCard",
  components: {
    Badge,
    Dot,
    ProfilePictureIcons,
    ThreadActions
  },
  props: {
    currentThread: Object,
    thread: Object,
    index: [String, Number],
    threadsDataLength: Number,
    isBanned: Boolean,
    isAdminMessage: true,

  },
  data() {
    return {
      getWidth: getWidth,
      stripTags: stripTags,
      systemAvatar: themeConfig.app.logo,
    }
  },
  computed: {
    ...mapGetters('chat', ['notReadCounter']),
    ...mapGetters('account', ['userData']),
    currentThreadId() {
      return this.currentThread ? this.currentThread.id : null;
    },
  },
  methods: {
    notReadThreadMessages(thread) {
      if(this.notReadCounter) {
        return this.notReadCounter.threadsCounter.find(el => el.threadId === thread.id)?.messagesNotRead
      }
      return 0;
    },
  }
}
</script>