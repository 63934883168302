var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a',{staticClass:"group flex flex-row items-center text-xs transition duration-200 ease-in-out\n      cursor-pointer focus:outline-none opacity-70 hover:opacity-100",class:{
        'cursor-pointer':_vm.currentThreadId != _vm.thread.id,
        'opacity-100': _vm.currentThreadId === _vm.thread.id,
        'mt-3 lg:mt-4':_vm.index===0,

       }},[_c('div',{staticClass:"flex flex-row items-start flex-none"},[_c('div',{staticClass:"flex-none relative"},[_c('Picture',{class:{
                'cursor-pointer scale-100 lg:group-hover:scale-105':_vm.currentThreadId != _vm.thread.id,
                'border-redAccentLight lg:scale-105': _vm.currentThreadId === _vm.thread.id,
            },attrs:{"is-chat":"","src":_vm.systemAvatar,"classes":"object-cover w-11 h-11 rounded-full\n                transform duration-200 ease-out border border-black\n                group-hover:border-redAccentLight"}}),_c('div',{staticClass:"absolute -right-2 -top-1"},[(_vm.notReadThreadMessages(_vm.thread)>0 && !_vm.isBanned)?[_c('Badge',{staticClass:"mb-3 ml-1",attrs:{"tagName":"span","variant":"counterSuccess"}},[_vm._v(" "+_vm._s(_vm.notReadThreadMessages(_vm.thread) > 100 ? '100+' : _vm.notReadThreadMessages(_vm.thread))+" ")])]:_vm._e()],2),_c('ProfilePictureIcons',{attrs:{"banned-flag":_vm.thread.targetUser.isBanned,"hidden-flag":_vm.thread.targetUser.isHidden}})],1)]),_c('div',{staticClass:"w-full flex flex-row justify-between ml-3"},[_c('div',{staticClass:"w-full flex flex-col justify-between"},[_c('div',{staticClass:"flex flex-row justify-between gap-2 items-start"},[(_vm.thread.targetUser)?_c('div',{staticClass:"block whitespace-nowrap text-base leading-tight group-hover:text-redAccentLight\n              flex flex-row mb-1 justify-start items-center",class:{
                'cursor-pointer group-hover:opacity-70':_vm.currentThreadId !== _vm.thread.id,
                'text-redAccentLight': _vm.currentThreadId === _vm.thread.id,
              }},[_c('span',{staticClass:"truncate",class:[
                  _vm.getWidth('ThreadCardName', 356),
                  {'font-semibold':_vm.notReadThreadMessages(_vm.thread)>0}
                ]},[_vm._v(" "+_vm._s(_vm.thread.adminMessageType)+" ")])]):_vm._e(),_c('div',{staticClass:"flex flex-row"},[_c('ThreadActions',{attrs:{"current-thread":_vm.thread,"isThread":"","only-delete":!!_vm.thread.isAdminMessage}})],1)]),_c('div',{staticClass:"text-s leading-tight thread-span group-hover:text-redAccentLight truncate whitespace-nowrap",class:[
              _vm.getWidth('ThreadCardMessage', 356),
              {
                'text-grayedDark':_vm.notReadThreadMessages(_vm.thread)===0,
                'text-black font-black':_vm.notReadThreadMessages(_vm.thread)>0,
                'cursor-pointer group-hover:opacity-70':_vm.currentThreadId !== _vm.thread.id,
                'text-redAccentLight opacity-100': _vm.currentThreadId === _vm.thread.id
              }
          ]},[(_vm.userData.isAdmin)?[_vm._v(" "+_vm._s(_vm.thread.adminMessageType)+" ")]:[_vm._v(" "+_vm._s(_vm.stripTags(_vm.thread.latestMessage))+" ")]],2)])])]),(_vm.index !== _vm.threadsDataLength - 1)?_c('hr',{staticClass:" mt-2 mb-2 border-solid border-grayedHR"}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }